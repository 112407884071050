require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@babel/polyfill")
require("tether")
require("bezier-easing")
import "@hotwired/turbo-rails"

// See https://gorails.com/episodes/how-to-use-jquery-with-esbuild
// why it's needed to import jquery this way
import "./src/jquery"
import "jquery-ui-bundle"

import "bootstrap/dist/js/bootstrap.bundle.js"
import noUiSlider from 'nouislider';
// Import all components from Audi UI library
import aui from './audi-ui.min.js';
window.aui = aui;
